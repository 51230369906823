import React from 'react'
import GHLogo from '../images/logo.png'
import styled from "styled-components";

const Img = styled.img`
  @media (max-width: calc(${props => props.theme.breakpoints[0]} + 1px)) {
    width: 100px;
  }
`

const Logo = () => (
    <Img src={GHLogo} />
)

export default Logo
